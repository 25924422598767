import toast from 'react-hot-toast';
import axios from 'axios';
import moment from 'moment';

const ApiRoot = `${process.env.REACT_APP_ENGAGEMENT_API_ROOT}/engagement`;

const BuildQueryString = function (firstName, lastName, emailAddress, activityCode, instanceCode, courseStartDate, sendOn, isSent, hasEmailBounced, survey) {
  let queryString = '';
  if (firstName !== '') {
    queryString += `firstName=${firstName}&`;
  }

  if (lastName !== '') {
    queryString += `lastName=${lastName}&`;
  }

  if (emailAddress !== '') {
    queryString += `emailAddress=${emailAddress}&`;
  }

  if (activityCode !== '') {
    queryString += `activityCode=${activityCode}&`;
  }

  if (instanceCode !== '') {
    queryString += `instanceCode=${instanceCode}&`;
  }

  if (courseStartDate !== '') {
    queryString += `courseStartDate=${courseStartDate}&`;
  }

  if (sendOn !== '') {
    queryString += `sendOn=${sendOn}&`;
  }

  if (isSent !== '' && isSent !== false) {
    queryString += `isSent=${isSent}&`;
  }

  if (hasEmailBounced !== '') {
    queryString += `hasEmailBounced=${hasEmailBounced}&`;
  }

  if (survey !== '') {
    queryString += `survey=${survey}&`;
  }

  if (queryString !== '') queryString = queryString.slice(0, -1);

  return queryString;
};

const GetEngagementHistory = async function (
  firstName = '',
  lastName = '',
  emailAddress = '',
  activityCode = '',
  instanceCode = '',
  courseStartDate = '',
  sendOn = moment(new Date()).format('yyyy-MM-DD'),
  isSent = '',
  hasEmailBounced = '',
  survey = '',
) {
  try {
    const queryString = BuildQueryString(
      firstName,
      lastName,
      emailAddress,
      activityCode,
      instanceCode,
      courseStartDate,
      sendOn,
      isSent,
      hasEmailBounced,
      survey,
    );

    const result = await axios.get(`${ApiRoot}?${queryString}`);
    toast.success('Search has completed successfully');
    return result.data;
  } catch {
    toast.error('Error listing engagement history please try again');
    return [];
  }
};

export default GetEngagementHistory;
