import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const RulesSelect = function (props) {
  const { index, rules, isNew } = props;

  const ruleOptions = rules.map((rule) => (
    <option value={rule.id} key={rule.id}>
      {rule.name}
    </option>
  ));

  return (
    <span>
      <Field name={`rules.${index}`} id={`rules.${index}`} as="select" className="form-control" disabled={!isNew}>
        <option value="">Select a rule</option>
        {ruleOptions}
      </Field>
      <ErrorMessage name={`rules.${index}`} className="d-block invalid-feedback" component="span" />
    </span>
  );
};
RulesSelect.propTypes = {
  index: PropTypes.number.isRequired,
  rules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default RulesSelect;
