import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const fields = [
  { id: 'programmeId', text: 'Programme ID' },
  { id: 'activityCode', text: 'Activity Code' },
  { id: 'workstream', text: 'Workstream' },
  { id: 'itkCpdType', text: 'ITK CPD Type' },
  { id: 'startDate', text: 'Start Date' },
  { id: 'endDate', text: 'End Date' },
  { id: 'numberOfResidentialPeriods', text: 'Number of Residential Periods' },
  { id: 'cpdImpactHours', text: 'CPD Impact Hours' },
];

export function convertFieldToText(fieldId) {
  const result = fields.find((field) => field.id === fieldId);
  return result == null ? 'Unknown' : result.text;
}

export function FieldsSelect(props) {
  const { setSelected } = props;
  const formikProps = useFormikContext();

  const fieldOptions = fields.map((field) => (
    <option value={field.id} key={field.id}>
      {field.text}
    </option>
  ));

  const onChange = (value) => {
    formikProps.setFieldValue('field', value);
    setSelected(value);
  };

  return (
    <span>
      <label htmlFor="field">Field</label>
      <Field name="field" as="select" className="form-control" onChange={(e) => onChange(e.target.value)}>
        <option value="">Select a field</option>
        {fieldOptions}
      </Field>
      <ErrorMessage name="field" className="d-block invalid-feedback" component="span" />
    </span>
  );
}
FieldsSelect.propTypes = {
  setSelected: PropTypes.func.isRequired,
};
