import { React } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import RuleForm from './RuleForm';
import { createRuleAsync } from '../../Helpers/API/RuleApi';

const CreateRule = function () {
  const navigate = useNavigate();
  const formValues = {
    name: '', field: '', operator: '', value: '', displayValue: '',
  };

  const onSubmit = (ruleObject) => {
    async function createRule() {
      const success = await createRuleAsync(ruleObject);
      if (success) navigate('/rules');
    }
    createRule();
  };

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/rules' }}>Rules</Breadcrumb.Item>
          <Breadcrumb.Item active>New Rule</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <RuleForm initialValues={formValues} saveText="Create rule" headerText="Create a new rule" onSubmit={onSubmit} />
    </div>
  );
};

export default CreateRule;
