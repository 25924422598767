import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import moment from 'moment';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import RulesetForm from './RulesetForm';
import { getRulesetByIdAsync, createRulesetAsync } from '../../Helpers/API/RulesetApi';

const CloneRuleset = function () {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValues, setFormValues] = useState({
    name: '',
    sourceSystem: '',
    pathwayId: '',
    order: 0,
    rules: [],
    expiryDate: '',
    deactivateSurveysOnExpiry: true,
  });

  const onSubmit = (rulesetObject) => {
    async function createRuleset() {
      const ruleset = rulesetObject;

      if (ruleset.expiryDate === '' || ruleset.expiryDate === null || ruleset.expiryDate === undefined) {
        ruleset.expiryDate = null;
        ruleset.deactivateSurveysOnExpiry = null;
      }

      const success = await createRulesetAsync(ruleset);
      if (success) navigate('/rulesets');
    }
    createRuleset();
  };

  useEffect(() => {
    async function getRule() {
      const {
        name, sourceSystem, pathwayId, order, rules, isExpired, expiryDate, deactivateSurveysOnExpiry,
      } = await getRulesetByIdAsync(id);

      const expiryDateString = isExpired || expiryDate === null || expiryDate === undefined ? '' : moment(expiryDate).format('YYYY-MM-DD');
      const deactivateSurveysOnExpiryValue = isExpired ? true : deactivateSurveysOnExpiry;

      const cloneName = `Clone of ${name}`;
      setFormValues({
        name: cloneName, sourceSystem, pathwayId, order, rules, expiryDate: expiryDateString, deactivateSurveysOnExpiry: deactivateSurveysOnExpiryValue,
      });
    }
    getRule();
  }, []);
  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/rulesets' }}>Rulesets</Breadcrumb.Item>
          <Breadcrumb.Item active>Clone Ruleset</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <RulesetForm initialValues={formValues} saveText="Clone ruleset" headerText="Clone an existing ruleset" onSubmit={onSubmit} isUpdateMode />
    </div>
  );
};

export default CloneRuleset;
