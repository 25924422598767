import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import { getAllPathwaysAsync } from '../API/PathwayApi';

const PathwaysSelect = function () {
  const [pathways, setPathways] = useState([]);

  useEffect(() => {
    async function getPathways() {
      const allPathways = await getAllPathwaysAsync();
      setPathways(allPathways);
    }
    getPathways();
  }, []);

  const pathwayOptions = pathways.map((pathway) => (
    <option value={pathway.id} key={pathway.id}>
      {pathway.name}
    </option>
  ));

  return (
    <span>
      <label htmlFor="pathwayId">Pathway</label>
      <Field name="pathwayId" as="select" className="form-control">
        <option value="">Select a pathway</option>
        {pathwayOptions}
      </Field>
      <ErrorMessage name="pathwayId" className="d-block invalid-feedback" component="span" />
    </span>
  );
};

export default PathwaysSelect;
