import toast from 'react-hot-toast';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const ApiRoot = `${process.env.REACT_APP_ENGAGEMENT_API_ROOT}/engagement`;

axiosRetry(axios, {
  retries: 5,
  retryDelay: (retryCount) => retryCount * 3000,
});

export async function getEngagementPreview(courseOccurrenceId) {
  try {
    const result = await axios.get(`${ApiRoot}/${courseOccurrenceId}`);
    return result.data;
  } catch {
    toast.error('Error loading engagements please try again');
    return {};
  }
}

export async function deleteEngagements(courseOccurrenceId) {
  let success = true;

  try {
    const result = await axios.delete(`${ApiRoot}/${courseOccurrenceId}`);
    if (result.status !== 204) success = false;
  } catch (exception) {
    success = false;
  }

  if (success) toast.success('Engagements deleted successfully');
  else toast.error('Error deleting engagements please try again');

  return success;
}

export async function resendEmail(id) {
  let success = true;

  try {
    await axios.post(`${ApiRoot}/email/${id}`);
  } catch {
    success = false;
  }

  if (success) toast.success('An attempt to resend the email will take place shortly');
  else toast.error('Error resending email please try again');

  return success;
}
