import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import appRoles from '../../Helpers/Auth/Roles';

const Dashboard = function () {
  const { instance } = useMsal();
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const onLoad = async () => {
    const currentAccount = instance.getAllAccounts()[0];

    if (currentAccount && currentAccount.idTokenClaims.roles) {
      const adminRole = currentAccount.idTokenClaims.roles.indexOf(appRoles.admin) > -1;
      setIsAdmin(adminRole);

      const networkPartnerRole = currentAccount.idTokenClaims.roles.indexOf(appRoles.networkPartner) > -1;
      if (networkPartnerRole) navigate('/engagement-history');
    }
  };

  useEffect(() => {
    onLoad();
  }, [instance]);

  return (
    <div>
      {
        isAdmin ? (
          <div>
            <h4>Dashboard</h4>
            <ul>
              <li><Link to="/rules" className="nav-link">Rules</Link></li>
              <li><Link to="/rulesets" className="nav-link">Rulesets</Link></li>
              <li><Link to="/pathways" className="nav-link">Pathways</Link></li>
              <li><Link to="/surveys" className="nav-link">Surveys</Link></li>
              <li><Link to="/admin" className="nav-link">Admin tools</Link></li>
              <li><Link to="/engagement-history" className="nav-link">Engagement History</Link></li>
            </ul>
          </div>
        )
          : ''
      }
    </div>
  );
};

export default Dashboard;
