import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PathwayForm from './PathwayForm';
import { getPathwayByIdAsync, updatePathwayAsync } from '../../Helpers/API/PathwayApi';

const UpdatePathway = function () {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValues, setFormValues] = useState({
    name: '',
    surveys: [],
  });

  const onSubmit = (pathwayObject) => {
    async function updatePathway() {
      const success = await updatePathwayAsync(id, pathwayObject);
      if (success) navigate('/pathways');
    }
    updatePathway();
  };

  useEffect(() => {
    const getPathway = async () => {
      const {
        name,
        surveys,
      } = await getPathwayByIdAsync(id);

      surveys.forEach((survey) => {
        surveys.find((s) => s.id === survey.id).id = survey.id.toUpperCase();
      });

      setFormValues({
        name,
        surveys,
      });
    };
    getPathway();
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/pathways' }}>Pathways</Breadcrumb.Item>
          <Breadcrumb.Item active>Update Pathway</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <PathwayForm initialValues={formValues} saveText="Update pathway" headerText="Update an existing pathway" onSubmit={onSubmit} />
    </div>
  );
};

export default UpdatePathway;
