import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import RelatedEngagementModal from './RelatedEngagementModal';

const RelatedEngagementTableRow = function (props) {
  const { engagement } = props;

  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const handleClose = () => setShowModal(false);

  const handleShow = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  return (
    <tr>
      <td>
        <RelatedEngagementModal show={showModal} handleClose={handleClose} engagement={selectedRow} />
        {engagement.surveyName}
      </td>
      <td className="d-none d-sm-table-cell">
        {engagement.activityCode}
        {' - '}
        {engagement.instanceCode}
      </td>
      <td className="d-none d-sm-table-cell">{moment(engagement?.sendSurveyOn).format('DD/MM/yyyy')}</td>
      <td className="d-none d-sm-table-cell">
        {
          engagement.hasEmailBounced
            ? 'Yes'
            : 'No'
        }
      </td>
      <td>
        <Button variant="primary" size="sm" onClick={() => handleShow(engagement)}>
          View Details
        </Button>
      </td>
    </tr>
  );
};
RelatedEngagementTableRow.propTypes = {
  engagement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    activityCode: PropTypes.string.isRequired,
    instanceCode: PropTypes.string.isRequired,
    surveyName: PropTypes.string.isRequired,
    hasEmailBounced: PropTypes.bool.isRequired,
    sendSurveyOn: PropTypes.string.isRequired,
  }).isRequired,
};

export default RelatedEngagementTableRow;
