import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getSurveyByIdAsync, updateSurveyAsync } from '../../Helpers/API/SurveyApi';
import SurveyForm from './SurveyForm';
import SurveyMissing from './SurveyMissing';

const UpdateSurvey = function () {
  const [surveyMissing, setSurveyMissing] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValues, setFormValues] = useState({
    name: '',
    alchemerId: '',
    emailTemplate: '',
  });

  const onSubmit = (surveyObject) => {
    async function updateSurvey() {
      const { success, isSurveyMissing } = await updateSurveyAsync(id, surveyObject);
      if (success) navigate('/surveys');
      setSurveyMissing(isSurveyMissing);
    }
    updateSurvey();
  };

  useEffect(() => {
    async function getSurvey() {
      const { name, alchemerId, emailTemplate } = await getSurveyByIdAsync(id);
      setFormValues({ name, alchemerId, emailTemplate });
    }
    getSurvey();
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/surveys' }}>Surveys</Breadcrumb.Item>
          <Breadcrumb.Item active>Update Survey</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <SurveyMissing isSurveyMissing={surveyMissing} />
      <SurveyForm initialValues={formValues} saveText="Update survey" headerText="Update an existing survey" onSubmit={onSubmit} />
    </div>
  );
};

export default UpdateSurvey;
