import { React, useState } from 'react';
import * as Yup from 'yup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert, Col, Row, FormGroup, Button,
} from 'react-bootstrap';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { invokeProcessCourseOccurance } from '../../Helpers/API/FunctionsApi';

const ProcessCourse = function () {
  const [isProcessing, setIsProcessing] = useState(false);
  const [buttonText, setButtonText] = useState('Process Course');

  const navigate = useNavigate();

  const initialValues = {
    courseOccuranceId: '',
  };

  const validationSchema = Yup.object().shape({
    courseOccuranceId: Yup.string().required('Please enter an course occurrence ID'),
  });

  const onSubmit = (courseOccuranceIdObject) => {
    async function processCourse() {
      setButtonText('Processing Course....');
      setIsProcessing(true);

      const success = await invokeProcessCourseOccurance(courseOccuranceIdObject.courseOccuranceId);
      if (success) navigate('/admin');

      setButtonText('Process Course');
      setIsProcessing(false);
    }

    ConfirmDialog({
      operation: 'process',
      entityName: 'this course',
      confirmationText: 'process it',
      onDelete: processCourse,
      closeBeforeAction: true,
    });
  };

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
            Admin Tools
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Process Course Occurrence</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Process Course Occurrence</h4>
      <Alert key="danger" variant="primary" className="form-wrapper">
        <p>
          Please be aware that there are no date restrictions in place as part of this tool.
          The course will be processed regardless of it&apos;s start data and all overdue surveys will be
          created and emailed to the attendees.
        </p>
      </Alert>
      <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize="true" validationSchema={validationSchema} validateOnMount="true">
        {({
          isValid,
        }) => (
          <Form>
            <Row>
              <FormGroup as={Col} className="form-group col-md-6 col-12">
                <label htmlFor="courseOccuranceId">Course occurrence ID</label>
                <Field id="courseOccuranceId" name="courseOccuranceId" type="text" className="form-control" />
                <ErrorMessage name="courseOccuranceId" className="d-block invalid-feedback" component="span" />
              </FormGroup>
            </Row>
            <div className="form-submission">
              <Button variant="primary" block="block" type="submit" className="create-btn" disabled={!isValid || isProcessing}>
                {buttonText}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProcessCourse;
