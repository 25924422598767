import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getAllPathwaysAsync } from '../../Helpers/API/PathwayApi';
import ConflictError from '../ConflictError/ConflictError';
import PathwayTableRow from './PathwayTableRow';

const PathwayList = function () {
  const [pathways, setPathways] = useState([]);
  const [isConflicted, setIsConflicted] = useState(false);
  const [conflicts, setConflicts] = useState([]);

  useEffect(() => {
    async function getPathways() {
      const data = await getAllPathwaysAsync();
      setPathways(data);
    }
    getPathways();
  }, []);

  const deleteRow = (id) => {
    const rows = [...pathways];
    setPathways(rows.filter((pathway) => pathway.id !== id));
    setIsConflicted(false);
  };

  const showConflictNotification = (conflicted) => {
    setIsConflicted(true);
    setConflicts(conflicted.map((conflictedItem) => <li key={conflictedItem}>{conflictedItem}</li>));
  };

  const PathwaysTable = () => pathways.map(
    (pathway) => <PathwayTableRow pathway={pathway} key={pathway.id} deleteRow={deleteRow} showConflictNotification={showConflictNotification} />,
  );

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Pathways</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Pathways</h4>
      <div className="table-wrapper">
        <ConflictError primaryEntity="pathway" secondaryEntity="rulesets" isConflicted={isConflicted} conflicts={conflicts} />
        <Link to="/pathways/create-pathway/" className="header-link btn">
          Create a new pathway
        </Link>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th className="grid-actions-header-small">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{PathwaysTable()}</tbody>
        </Table>
      </div>
    </div>

  );
};

export default PathwayList;
