import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const operators = [
  { id: 'equals', text: 'Equals' },
  { id: 'contains', text: 'Contains' },
  { id: 'startsWith', text: 'Starts With' },
  { id: 'endsWith', text: 'Ends With' },
  { id: 'lessThan', text: 'Less Than' },
  { id: 'lessThanEqual', text: 'Less Than or Equal to' },
  { id: 'greaterThan', text: 'Greater Than' },
  { id: 'greaterThanEqual', text: 'Greater Than or Equal to' },
];

export function convertOperatorToText(operatorId) {
  const result = operators.find((operator) => operator.id === operatorId);
  return result == null ? 'Unknown' : result.text;
}

export function OperatorsSelect(props) {
  const { setSelected } = props;
  const formikProps = useFormikContext();

  const operatorOptions = operators.map((operator) => (
    <option value={operator.id} key={operator.id}>
      {operator.text}
    </option>
  ));

  const onChange = (value) => {
    formikProps.setFieldValue('operator', value);
    setSelected(value);
  };

  return (
    <span>
      <label htmlFor="operator">Operator</label>
      <Field name="operator" as="select" className="form-control" onChange={(e) => onChange(e.target.value)}>
        <option value="">Select an operator</option>
        {operatorOptions}
      </Field>
      <ErrorMessage name="operator" className="d-block invalid-feedback" component="span" />
    </span>
  );
}
OperatorsSelect.propTypes = {
  setSelected: PropTypes.func.isRequired,
};
