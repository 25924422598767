import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import appRoles from './Roles';

const AdminRouteGuard = function (props) {
  const { children } = props;

  const { instance } = useMsal();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const onLoad = async () => {
    const currentAccount = instance.getAllAccounts()[0];

    if (currentAccount && currentAccount.idTokenClaims.roles) {
      const adminRole = currentAccount.idTokenClaims.roles.indexOf(appRoles.admin) > -1;

      setIsAdmin(adminRole);
      if (!adminRole) navigate('/');
    }
  };

  useEffect(() => {
    onLoad();
  }, [instance]);

  return (
    <span>
      {
        isAdmin ? (<div>{children}</div>)
          : ''
      }
    </span>
  );
};
AdminRouteGuard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AdminRouteGuard;
