import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import BounceModal from './BounceModal';

const BounceTableRow = function (props) {
  const { engagement } = props;

  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const handleClose = () => setShowModal(false);

  const handleShow = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  return (
    <tr>
      <td>
        <BounceModal show={showModal} handleClose={handleClose} engagement={selectedRow} />
        {engagement.emailAddress}
      </td>
      <td className="d-none d-sm-table-cell">
        {
          engagement.bounceType === 'hard-bounce'
            ? 'Hard bounce'
            : 'Soft bounce'
        }
      </td>
      <td className="d-none d-sm-table-cell">
        {engagement.firstName}
        { ' ' }
        {engagement.lastName}
      </td>
      <td className="d-none d-sm-table-cell">{engagement.surveyName}</td>
      <td className="d-none d-sm-table-cell">{moment(engagement?.sendSurveyOn).format('DD/MM/yyyy')}</td>
      <td>
        <Link to={`/admin/bounces/resolve/${engagement.id}`} className="update-link">
          Resolve
        </Link>
        <Button variant="primary" size="sm" onClick={() => handleShow(engagement)}>
          View Details
        </Button>
      </td>
    </tr>
  );
};
BounceTableRow.propTypes = {
  engagement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    bounceType: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    surveyName: PropTypes.string.isRequired,
    sendSurveyOn: PropTypes.string.isRequired,
  }).isRequired,
};

export default BounceTableRow;
