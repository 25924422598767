import { React } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { deleteSurveyAsync, refreshSurveyMetadata } from '../../Helpers/API/SurveyApi';

const SurveyTableRow = function (props) {
  const { survey, deleteRow, showConflictNotification } = props;

  const deleteSurveyOnClick = (name) => {
    async function deleteSurvey() {
      const { success, isConflicted, conflicts } = await deleteSurveyAsync(survey.id);
      if (success) deleteRow(survey.id);
      if (isConflicted) showConflictNotification(conflicts);
    }

    ConfirmDialog({ entityName: name, onDelete: deleteSurvey });
  };

  const refreshSurveyMetadataOnClick = async () => {
    await refreshSurveyMetadata(survey.id);
  };

  return (
    <tr>
      <td>{survey.name}</td>
      <td className="d-none d-sm-table-cell">{survey.alchemerId}</td>
      <td className="d-none d-sm-table-cell">{survey.emailTemplate}</td>
      <td>
        <Link to={`/surveys/update-survey/${survey.id}`} className="update-link">
          Update
        </Link>
        <Button onClick={() => refreshSurveyMetadataOnClick()} size="sm" variant="primary" className="action-button-padded">
          Refresh Metadata
        </Button>
        <Button onClick={() => deleteSurveyOnClick(survey.name)} size="sm" variant="danger" className="action-button-padded">
          Delete
        </Button>
      </td>
    </tr>
  );
};
SurveyTableRow.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    alchemerId: PropTypes.string.isRequired,
    emailTemplate: PropTypes.string.isRequired,
  }).isRequired,
  deleteRow: PropTypes.func.isRequired,
  showConflictNotification: PropTypes.func.isRequired,
};

export default SurveyTableRow;
