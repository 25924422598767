import React from 'react';
import * as Yup from 'yup';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { FormGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const SurveyForm = function (props) {
  const navigate = useNavigate();

  const onCancel = () => {
    navigate('/surveys');
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name'),
    alchemerId: Yup.string().required('Please enter an Alchemer ID'),
    emailTemplate: Yup.string().required('Please enter a Mandrill template slug'),
  });

  const {
    saveText, headerText, initialValues, onSubmit,
  } = props;

  return (
    <div className="form-wrapper">
      <h4>{headerText}</h4>
      <Formik initialValues={initialValues} enableReinitialize="true" validationSchema={validationSchema} validateOnMount="true" onSubmit={onSubmit}>
        {({
          isValid,
        }) => (
          <Form>
            <FormGroup className="form-group">
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" type="text" className="form-control" />
              <ErrorMessage name="name" className="d-block invalid-feedback" component="span" />
            </FormGroup>
            <FormGroup className="form-group">
              <label htmlFor="alchemerId">Alchemer ID</label>
              <Field id="alchemerId" name="alchemerId" type="text" className="form-control" />
              <ErrorMessage name="alchemerId" className="d-block invalid-feedback" component="span" />
            </FormGroup>
            <FormGroup className="form-group">
              <label htmlFor="emailTemplate">Mandrill Template Slug</label>
              <Field id="emailTemplate" name="emailTemplate" type="text" className="form-control" />
              <ErrorMessage name="emailTemplate" className="d-block invalid-feedback" component="span" />
            </FormGroup>
            <div className="form-submission">
              <Button variant="primary" block="block" type="submit" className="create-btn" disabled={!isValid}>
                {saveText}
              </Button>
              <Button variant="danger" block="block" type="button" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
SurveyForm.propTypes = {
  saveText: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    alchemerId: PropTypes.string.isRequired,
    emailTemplate: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SurveyForm;
