import { React } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

const RelatedEngagementModal = function (props) {
  const { engagement, show, handleClose } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Engagement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>Attendee:</b>
          <br />
          {engagement?.firstName}
          { ' ' }
          {engagement?.lastName}
        </p>
        <p>
          <b>Email Address:</b>
          <br />
          {engagement?.emailAddress}
        </p>
        <p>
          <b>Course Details:</b>
          <br />
          {engagement?.activityCode}
          { ' - '}
          {engagement?.instanceCode}
        </p>
        <p>
          <b>Course Start Date:</b>
          <br />
          {moment(engagement?.courseStartDate).format('DD/MM/yyyy')}
        </p>
        <p>
          <b>Survey:</b>
          <br />
          {engagement?.surveyName}
        </p>
        <p>
          <b>Send Survey On:</b>
          <br />
          {moment(engagement?.sendSurveyOn).format('DD/MM/yyyy')}
        </p>
        <p>
          <b>Has Bounced:</b>
          <br />
          {
            engagement?.hasEmailBounced
              ? 'Yes'
              : 'No'
          }
        </p>
        <p className="url-container">
          <b>Survey URL:</b>
          <br />
          {
            engagement?.surveyUri === ''
              ? 'N/A'
              : <a href={engagement?.surveyUri} title="Survey URL" target="_blank" rel="noreferrer">{engagement?.surveyUri}</a>
          }
        </p>
      </Modal.Body>
    </Modal>
  );
};
RelatedEngagementModal.defaultProps = {
  engagement: null,
};
RelatedEngagementModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  engagement: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailAddress: PropTypes.string,
    activityCode: PropTypes.string,
    instanceCode: PropTypes.string,
    courseStartDate: PropTypes.string,
    surveyName: PropTypes.string,
    sendSurveyOn: PropTypes.string,
    surveyUri: PropTypes.string,
    hasEmailBounced: PropTypes.bool,
  }),
};

export default RelatedEngagementModal;
