import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { FormGroup, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OperatorsSelect } from '../../Helpers/SelectValues/Operators';
import { FieldsSelect } from '../../Helpers/SelectValues/Fields';
import RuleValueSelector from './RuleValueSelector';

const RuleForm = function (props) {
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedText, setSelectedText] = useState(null);

  const navigate = useNavigate();

  const onCancel = () => {
    navigate('/rules');
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name'),
    field: Yup.string().required('Please select a field'),
    operator: Yup.string().required('Please select an operator'),
    value: Yup.string().required('Please select a value'),
  });

  const {
    saveText, headerText, initialValues, onSubmit,
  } = props;

  useEffect(() => {
    if (initialValues.field !== '') setSelectedField(initialValues.field);
    if (initialValues.operator !== '') setSelectedOperator(initialValues.operator);
    if (initialValues.displayValue !== '') setSelectedText(initialValues.displayValue);
  }, [initialValues]);

  const submit = (rule) => {
    const ruleWithDisplayValue = rule;
    ruleWithDisplayValue.displayValue = selectedText;

    onSubmit(ruleWithDisplayValue);
  };

  return (
    <div className="form-wrapper">
      <h4>{headerText}</h4>
      <Formik initialValues={initialValues} enableReinitialize="true" validationSchema={validationSchema} validateOnMount="true" onSubmit={submit}>
        {({
          isValid,
        }) => (
          <Form>
            <FormGroup className="form-group">
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" type="text" className="form-control" />
              <ErrorMessage name="name" className="d-block invalid-feedback" component="span" />
            </FormGroup>
            <FormGroup className="form-group">
              <FieldsSelect setSelected={setSelectedField} />
            </FormGroup>
            <FormGroup className="form-group">
              <OperatorsSelect setSelected={setSelectedOperator} />
            </FormGroup>
            <RuleValueSelector setSelectedText={setSelectedText} selectedField={selectedField} selectedOperator={selectedOperator} />
            <div className="form-submission">
              <Button variant="primary" block="block" type="submit" className="create-btn" disabled={!isValid}>
                {saveText}
              </Button>
              <Button variant="danger" block="block" type="button" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
RuleForm.propTypes = {
  saveText: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RuleForm;
