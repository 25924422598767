import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import {
  dynamicsItkCpdTypes, DynamicsLookupSelect, dynamicsProgrammes, dynamicsWorkstreams,
} from '../../Helpers/SelectValues/DynamicsLookup';

const RuleValueSelector = function (props) {
  const {
    setSelectedText, selectedField, selectedOperator,
  } = props;

  const formikProps = useFormikContext();

  const valueOnChange = (value) => {
    formikProps.setFieldValue('value', value);
    setSelectedText(value);
  };

  return (
    <span>
      {!(selectedField === 'programmeId' && selectedOperator === 'equals')
        && !(selectedField === 'workstream' && selectedOperator === 'equals')
        && !(selectedField === 'itkCpdType' && selectedOperator === 'equals')
        && (
          <FormGroup className="form-group">
            <label htmlFor="value">Value</label>
            <Field id="value" name="value" type="text" className="form-control" onChange={(e) => valueOnChange(e.target.value)} />
            <ErrorMessage name="value" className="d-block invalid-feedback" component="span" />
          </FormGroup>
        )}
      <FormGroup className="form-group">
        {selectedField === 'programmeId' && selectedOperator === 'equals' && (
          <DynamicsLookupSelect
            type={dynamicsProgrammes}
            name="value"
            label="Programme"
            placeholder="Select a programme"
            setSelectedValueText={setSelectedText}
          />
        )}
        {selectedField === 'workstream' && selectedOperator === 'equals' && (
          <DynamicsLookupSelect
            type={dynamicsWorkstreams}
            name="value"
            label="Workstream"
            placeholder="Select a workstream"
            setSelectedValueText={setSelectedText}
          />
        )}
        {selectedField === 'itkCpdType' && selectedOperator === 'equals' && (
          <DynamicsLookupSelect
            type={dynamicsItkCpdTypes}
            name="value"
            label="ITK CPD type"
            placeholder="Select an ITK CPD type"
            setSelectedValueText={setSelectedText}
          />
        )}
      </FormGroup>
    </span>
  );
};
RuleValueSelector.defaultProps = {
  selectedField: null,
  selectedOperator: null,
};
RuleValueSelector.propTypes = {
  setSelectedText: PropTypes.func.isRequired,
  selectedField: PropTypes.string,
  selectedOperator: PropTypes.string,
};

export default RuleValueSelector;
