import { React } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const EngagementPreviewTableRow = function (props) {
  const { engagement } = props;

  return (
    <tr>
      <td>
        {engagement.firstName}
        &nbsp;
        {engagement.lastName}
      </td>
      <td className="d-none d-sm-table-cell">{engagement.emailAddress}</td>
      <td className="d-none d-sm-table-cell">{engagement.surveyName}</td>
      <td className="d-none d-sm-table-cell">{(moment(engagement.sendSurveyOn).format('DD/MM/yyyy'))}</td>
      <td className="d-none d-sm-table-cell">{(engagement.isSurveySent ? 'Yes' : 'No')}</td>
    </tr>
  );
};
EngagementPreviewTableRow.propTypes = {
  engagement: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    surveyName: PropTypes.string.isRequired,
    sendSurveyOn: PropTypes.string.isRequired,
    isSurveySent: PropTypes.bool.isRequired,
  }).isRequired,
};

export default EngagementPreviewTableRow;
