import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const applyDeltaFrom = [
  { id: 'start', text: 'Start' },
  { id: 'end', text: 'End' },
];

export function convertApplyDeltaFromOptionsToText(applyDeltaFromId) {
  const result = applyDeltaFrom.find((applyDeltaFromOption) => applyDeltaFromOption.id === applyDeltaFromId);
  return result == null ? 'Unknown' : result.text;
}

export function ApplyDeltaFromSelect(props) {
  const { index } = props;

  const applyDeltaFromOptions = applyDeltaFrom.map((applyDeltaFromOption) => (
    <option value={applyDeltaFromOption.id} key={applyDeltaFromOption.id}>
      {applyDeltaFromOption.text}
    </option>
  ));

  return (
    <span>
      <Field
        id={`surveys.${index}.applyDeltaFrom`}
        name={`surveys.${index}.applyDeltaFrom`}
        as="select"
        className="form-control"
      >
        <option value="">From</option>
        {applyDeltaFromOptions}
      </Field>
      <ErrorMessage name={`surveys.${index}.applyDeltaFrom`} className="d-block invalid-feedback" component="span" />
    </span>
  );
}
ApplyDeltaFromSelect.propTypes = {
  index: PropTypes.number.isRequired,
};
