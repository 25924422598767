/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Formik, Form, Field, ErrorMessage, FieldArray,
} from 'formik';
import { FormGroup, Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SourceSystemSelect } from '../../Helpers/SelectValues/SourceSystems';
import PathwaysSelect from '../../Helpers/SelectValues/Pathways';
import RulesSelect from '../../Helpers/SelectValues/Rules';
import { getAllRulesAsync } from '../../Helpers/API/RuleApi';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const RulesetForm = function (props) {
  const navigate = useNavigate();

  const [rules, setRuleValues] = useState([]);

  useEffect(() => {
    async function getRules() {
      const allRules = await getAllRulesAsync();
      setRuleValues(allRules);
    }
    getRules();
  }, []);

  const onCancel = () => {
    navigate('/rulesets');
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name'),
    sourceSystem: Yup.string().required('Please select a source system'),
    pathwayId: Yup.string().required('Please select a pathway'),
    order: Yup.number().required('Please enter an order'),
    rules: Yup.array().of(Yup.string()).min(1, 'Please enter rules'),
    expiryDate: Yup.string().nullable(),
    deactivateSurveysOnExpiry: Yup.boolean().nullable(),
  });

  const {
    saveText, headerText, initialValues, onSubmit, isUpdateMode,
  } = props;

  const initialDeactivateSurveysOnExpiryValue = initialValues.deactivateSurveysOnExpiry;

  let { deactivateSurveysOnExpiry } = initialValues;

  const { expiryDate } = initialValues;
  let deactivateSurveysOnExpiryVisible = expiryDate !== '';

  return (
    <div className="form-wrapper">
      <h4>{headerText}</h4>
      <Formik initialValues={initialValues} enableReinitialize="true" validationSchema={validationSchema} validateOnMount="true" onSubmit={onSubmit}>
        {({
          isValid, values, setFieldValue,
        }) => (
          <Form>
            <FormGroup className="form-group">
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" type="text" className="form-control" />
              <ErrorMessage name="name" className="d-block invalid-feedback" component="span" />
            </FormGroup>
            <FormGroup className="form-group">
              <SourceSystemSelect isUpdateMode={isUpdateMode} />
            </FormGroup>
            <FormGroup className="form-group">
              <PathwaysSelect />
            </FormGroup>
            <FormGroup className="form-group">
              <label htmlFor="order">Order</label>
              <Field id="order" name="order" type="number" className="form-control" />
              <ErrorMessage name="order" className="d-block invalid-feedback" component="span" />
            </FormGroup>
            <FormGroup className="form-group">
              <label htmlFor="expiryDate">Expiry Date</label>
              <Field
                id="expiryDate"
                name="expiryDate"
                type="date"
                className="form-control"
                value={values.expiryDate}
                onChange={(e) => {
                  // const selectedExpiryDate = e.target.value === '' ? null : e.target.value;
                  deactivateSurveysOnExpiryVisible = e.target.value !== '';
                  e.currentTarget.blur();
                  setFieldValue('expiryDate', e.target.value);
                  setFieldValue('deactivateSurveysOnExpiry', deactivateSurveysOnExpiry);
                }}
              />
            </FormGroup>
            {deactivateSurveysOnExpiryVisible && (
              <span>
                <FormGroup className="form-group">
                  <p>
                    <label htmlFor="deactivateSurveysOnExpiry">Deactivate surveys after the ruleset expires?</label>
                  </p>
                  <ToggleSwitch
                    id="deactivateSurveysOnExpiry"
                    name="deactivateSurveysOnExpiry"
                    checked={initialDeactivateSurveysOnExpiryValue}
                    onChange={(checked) => {
                      deactivateSurveysOnExpiry = checked;
                      setFieldValue('deactivateSurveysOnExpiry', deactivateSurveysOnExpiry);
                    }}
                  />
                </FormGroup>
              </span>
            )}
            <FieldArray name="rules">
              {({ remove, push }) => (
                <div className="table-wrapper">
                  <Button
                    variant="primary"
                    block="block"
                    type="button"
                    className="create-btn header-link"
                    onClick={() => {
                      push('');
                    }}
                  >
                    Add rule
                  </Button>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Rule</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!values.rules.length > 0
                        ? (
                          <tr>
                            <td colSpan={3}>
                              <span className="d-block invalid-feedback">Please enter at least one rule</span>
                            </td>
                          </tr>
                        )
                        : (
                          values.rules.map((rule, index) => (
                            <tr key={index}>
                              <td>
                                <RulesSelect index={index} rules={rules} isNew={rule === ''} />
                              </td>
                              <td>
                                <Button onClick={() => remove(index)} size="sm" variant="danger">
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))
                        )}
                    </tbody>
                  </Table>
                </div>
              )}
            </FieldArray>
            <div className="form-submission">
              <Button variant="primary" block="block" type="submit" className="create-btn" disabled={!isValid}>
                {saveText}
              </Button>
              <Button variant="danger" block="block" type="button" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
RulesetForm.propTypes = {
  saveText: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sourceSystem: PropTypes.string.isRequired,
    pathwayId: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    rules: PropTypes.arrayOf(PropTypes.string),
    expiryDate: PropTypes.string,
    deactivateSurveysOnExpiry: PropTypes.bool,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isUpdateMode: PropTypes.bool.isRequired,
};

export default RulesetForm;
