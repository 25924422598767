import { React } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PathwayForm from './PathwayForm';
import { createPathwayAsync } from '../../Helpers/API/PathwayApi';

const CreatePathway = function () {
  const navigate = useNavigate();
  const formValues = {
    name: '',
    surveys: [],
  };

  const onSubmit = (pathwayObject) => {
    async function createPathway() {
      const success = await createPathwayAsync(pathwayObject);
      if (success) navigate('/pathways');
    }
    createPathway();
  };

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/pathways' }}>Pathways</Breadcrumb.Item>
          <Breadcrumb.Item active>New Pathway</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <PathwayForm initialValues={formValues} saveText="Create pathway" headerText="Create a new pathway" onSubmit={onSubmit} />
    </div>
  );
};

export default CreatePathway;
