import { React, useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Table, Alert, Col, Row, FormGroup, Button,
} from 'react-bootstrap';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import RelatedEngagementTableRow from './RelatedEnagementTableRow';
import { getBounce, updateBouncedEmail } from '../../Helpers/API/BounceApi';
import { resendEmail } from '../../Helpers/API/EngagementApi';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const ResolveBounce = function () {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bounce, setBounce] = useState();

  const initialValues = {
    emailAddress: '',
  };

  const onSubmit = (emailAddressObject) => {
    async function updateEmail() {
      const success = await updateBouncedEmail(bounce.engagement.emailAddress, emailAddressObject);
      if (success) navigate('/admin/bounces');
    }

    ConfirmDialog({
      operation: 'update',
      entityName: `${bounce.engagement.emailAddress} to ${emailAddressObject.emailAddress}`,
      confirmationText: 'update it',
      onDelete: updateEmail,
    });
  };

  const onTryAgain = async () => {
    async function resendTheEmail() {
      const success = await resendEmail(id);
      if (success) navigate('/admin/bounces');
    }

    ConfirmDialog({
      operation: 'resend',
      entityName: `${bounce.engagement.surveyName} to ${bounce.engagement.emailAddress}`,
      confirmationText: 'resend it',
      onDelete: resendTheEmail,
    });
  };

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().email('Please enter a valid email address').required('Please enter an email address'),
    confirmEmailAddress: Yup.string()
      .email('Please enter a valid email address')
      .required('Please confirm the email address')
      .oneOf([Yup.ref('emailAddress'), null], 'Both email addresses must match'),
  });

  useEffect(() => {
    async function getBounceDetails() {
      const data = await getBounce(id);
      setBounce(data);
    }
    getBounceDetails();
  }, []);

  const RelatedEngagementsTable = () => bounce?.relatedEngagements.map(
    (relatedEngagement) => <RelatedEngagementTableRow engagement={relatedEngagement} key={relatedEngagement.id} />,
  );

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
            Admin Tools
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/bounces' }}>Bounced Emails</Breadcrumb.Item>
          <Breadcrumb.Item active>Resolve</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Bounced Email</h4>
      <Alert key="danger" variant="primary" className="form-wrapper">
        {
          bounce?.engagement.bounceType === 'hard-bounce'
            ? (
              <span>
                <p>
                  When we tried to send the email we encountered a
                  <b> hard </b>
                  bounce. This is a permanent error which indicates the email address used not exist.
                  The only way to fix the problem is to update the email address and retry sending the email.
                  To update the email address and trigger a retry please complete the form below.
                </p>
                <p>
                  Note that all of the related engagement records shown above will also be updated and if any
                  of them have already failed to send they will be retried.
                </p>
              </span>
            )
            : (
              <span>
                <p>
                  When we tried to send the email we encountered a
                  <b> soft </b>
                  bounce. This is likely a permanent error which indicates the email address does not exist
                  however sometimes soft bounces can be caused by temporary issues such as a full mailbox. To
                  resolve the issue you can either update the email address, which will trigger a retry, or
                  attempty a retry without updating the email address.
                </p>
                <p>
                  Note that if you update the email address all of the related engagement records shown above will
                  also be updated and if any of them have already failed to send they will be retried.
                </p>
              </span>
            )
        }
      </Alert>
      <div className="table-wrapper">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Survey</th>
              <th>Email Address</th>
              <th className="d-none d-sm-table-cell">Course</th>
              <th className="d-none d-sm-table-cell">Start Date</th>
              <th className="d-none d-sm-table-cell">Attempted On</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{bounce?.engagement.surveyName}</td>
              <td>{bounce?.engagement.emailAddress}</td>
              <td className="d-none d-sm-table-cell">
                {bounce?.engagement.activityCode}
                {' - '}
                {bounce?.engagement.instanceCode}
              </td>
              <td className="d-none d-sm-table-cell">{moment(bounce?.engagement.courseStartDate).format('DD/MM/yyyy')}</td>
              <td className="d-none d-sm-table-cell">{moment(bounce?.engagement.sendSurveyOn).format('DD/MM/yyyy')}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h5 className="bounce-sub-heading">Related engagements</h5>
      <p>
        We&apos;ve found a number of records in the database which share the the email address we&apos;ve had issues sending to.
        These surveys may also fail to send in the future or may already have failed.
      </p>
      <div className="table-wrapper">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Survey</th>
              <th className="d-none d-sm-table-cell">Course</th>
              <th className="d-none d-sm-table-cell">Scheduled For</th>
              <th className="d-none d-sm-table-cell">Already bounced?</th>
              <th className="grid-actions-header-small">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{RelatedEngagementsTable()}</tbody>
        </Table>
      </div>
      <h5 className="bounce-sub-heading">Resolve issues</h5>
      {
        bounce?.engagement.bounceType === 'soft-bounce'
          ? (
            <div>
              <Button variant="danger" block="block" type="button" onClick={onTryAgain}>
                Retry without no changes
              </Button>
              <br />
              <br />
              <p><b>OR</b></p>
            </div>
          )
          : ''
      }
      <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize="true" validationSchema={validationSchema} validateOnMount="true">
        {({
          isValid,
        }) => (
          <Form>
            <Row>
              <FormGroup as={Col} className="form-group col-md-6 col-12">
                <label htmlFor="emailAddress">Email address</label>
                <Field id="emailAddress" name="emailAddress" type="text" className="form-control" />
                <ErrorMessage name="emailAddress" className="d-block invalid-feedback" component="span" />
              </FormGroup>
              <FormGroup as={Col} className="form-group col-md-6 col-12">
                <label htmlFor="confirmEmailAddress">Confirm Email address</label>
                <Field id="confirmEmailAddress" name="confirmEmailAddress" type="text" className="form-control" />
                <ErrorMessage name="confirmEmailAddress" className="d-block invalid-feedback" component="span" />
              </FormGroup>
            </Row>
            <div className="form-submission">
              <Button variant="primary" block="block" type="submit" className="create-btn" disabled={!isValid}>
                Update email address and retry
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResolveBounce;
