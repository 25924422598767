import { React } from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const SurveysSelect = function (props) {
  const {
    id, name, surveys, isNewRow, showLabel,
  } = props;

  const surveyOptions = surveys.map((survey) => (
    <option value={survey.id} key={survey.id}>
      {survey.name}
    </option>
  ));

  return (
    <span>
      {
        showLabel
          ? <label htmlFor={id}>Survey</label>
          : ''
      }
      <Field id={id} name={name} as="select" className="form-control" disabled={!isNewRow}>
        <option value="">Select a survey</option>
        {surveyOptions}
      </Field>
      <ErrorMessage name={name} className="d-block invalid-feedback" component="span" />
    </span>
  );
};
SurveysSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surveys: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  isNewRow: PropTypes.bool.isRequired,
  showLabel: PropTypes.bool.isRequired,
};

export default SurveysSelect;
