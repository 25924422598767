import { React } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { deleteRuleAsync } from '../../Helpers/API/RuleApi';
import { convertFieldToText } from '../../Helpers/SelectValues/Fields';
import { convertOperatorToText } from '../../Helpers/SelectValues/Operators';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const RuleTableRow = function (props) {
  const { rule, deleteRow, showConflictNotification } = props;

  const deleteRuleOnClick = (name) => {
    async function deleteRule() {
      const { success, isConflicted, conflicts } = await deleteRuleAsync(rule.id);
      if (success) deleteRow(rule.id);
      if (isConflicted) showConflictNotification(conflicts);
    }

    ConfirmDialog({ entityName: name, onDelete: deleteRule });
  };

  return (
    <tr>
      <td>{rule.name}</td>
      <td className="d-none d-sm-table-cell">{convertFieldToText(rule.field)}</td>
      <td className="d-none d-sm-table-cell">{convertOperatorToText(rule.operator)}</td>
      <td className="d-none d-sm-table-cell">{rule.displayValue}</td>
      <td>
        <Link to={`/rules/update-rule/${rule.id}`} className="update-link">
          Update
        </Link>
        <Button onClick={() => deleteRuleOnClick(rule.name)} size="sm" variant="danger">
          Delete
        </Button>
      </td>
    </tr>
  );
};
RuleTableRow.propTypes = {
  rule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.string.isRequired,
  }).isRequired,
  deleteRow: PropTypes.func.isRequired,
  showConflictNotification: PropTypes.func.isRequired,
};

export default RuleTableRow;
