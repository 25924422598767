import { React } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {
  invokeDynamicsDataPull, invokeGetScheduledEngagements, invokeExpireRulesets, invokePopulateMissingDrupalIds,
} from '../../Helpers/API/FunctionsApi';

const Admin = function () {
  const onDynamicsDataPull = async () => {
    await invokeDynamicsDataPull();
  };

  const onGetScheduledEngagements = async () => {
    await invokeGetScheduledEngagements();
  };

  const onExpireRulesets = async () => {
    await invokeExpireRulesets();
  };

  const onPopulateMissingDrupalIds = async () => {
    await invokePopulateMissingDrupalIds();
  };

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Admin Tools</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Admin Tools</h4>
      <div className="admin-tool">
        <h5>Manually run timed functions</h5>
        <p>
          Please note: invoking the dynamics data pull function may take a couple of minutes, please wait for a confirmation
          or error message to appear before pushing the button again
        </p>
        <Button variant="primary" block="block" type="button" className="create-btn" onClick={onDynamicsDataPull}>
          Invoke Dynamics Data Pull
        </Button>
        <Button variant="primary" block="block" type="button" className="create-btn" onClick={onGetScheduledEngagements}>
          Invoke Get Scheduled Engagements
        </Button>
        <Button variant="primary" block="block" type="button" className="create-btn" onClick={onExpireRulesets}>
          Invoke Expire Rulesets
        </Button>
      </div>
      <div className="admin-tool">
        <h5>Bounced Emails</h5>
        <p>View and resolve issues with survey emails which have bounced when they have been sent</p>
        <Button variant="primary" block="block" type="button" className="create-btn" as={Link} to="/admin/bounces">
          Bounced Emails
        </Button>
      </div>
      <div className="admin-tool">
        <h5>Populate missing Drupal user ID&apos;s</h5>
        <p>
          This function will attempt to look up the Drupal user ID for any engagement record where the ID is empty
        </p>
        <Button variant="primary" block="block" type="button" className="create-btn" onClick={onPopulateMissingDrupalIds}>
          Populate Missing Drupal User ID&apos;s
        </Button>
      </div>
      <div className="admin-tool">
        <h5>Pull a single course occurrence from Dynamics</h5>
        <p>
          This function will attempt to pull a single course occurrence, irrespective of start date, into ITK.
        </p>
        <Button variant="primary" block="block" type="button" className="create-btn" as={Link} to="/admin/process-course">
          Process Course Occurrence
        </Button>
      </div>
      <div className="admin-tool">
        <h5>Delete a single course occurrence from ITK</h5>
        <p>
          This function will delete all engagement records from ITK for a single course occurrence
        </p>
        <Button variant="primary" block="block" type="button" className="create-btn" as={Link} to="/admin/delete-course">
          Delete Course Occurrence
        </Button>
      </div>
    </div>
  );
};

export default Admin;
