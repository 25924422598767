import { React, useEffect } from 'react';
import {
  Navbar, Container, Row, Col, Nav,
} from 'react-bootstrap';
import './App.css';
import {
  BrowserRouter, Routes, Route, Link,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import axios from 'axios';
import CreateRule from './Components/Rules/create-rule.component';
import UpdateRule from './Components/Rules/update-rule.component';
import 'bootstrap/dist/css/bootstrap.min.css';
import RuleList from './Components/Rules/rule-list.component';
import Dashboard from './Components/Dashboard/dashboard-component';
import PathwayList from './Components/Pathways/pathway-list.component';
import RulesetList from './Components/Rulesets/ruleset-list.component';
import CreatePathway from './Components/Pathways/create-pathway.component';
import UpdatePathway from './Components/Pathways/update-pathway.component';
import CreateRuleset from './Components/Rulesets/create-ruleset.component';
import UpdateRuleset from './Components/Rulesets/update-ruleset.component';
import CloneRuleset from './Components/Rulesets/clone-ruleset.component';
import HistoryList from './Components/History/history-list.component';
import Admin from './Components/Admin/admin.component';
import SignOutButton from './Components/SignOutButton/SignOutButton';
import SurveyList from './Components/Surveys/survey-list.component';
import CreateSurvey from './Components/Surveys/create-survey.component';
import UpdateSurvey from './Components/Surveys/update-survey.component';
import BounceList from './Components/Bounces/bounces-list.component';
import ResolveBounce from './Components/Bounces/resolve-bounce.component';
import AdminRouteGuard from './Helpers/Auth/AdminRouteGuard';
import ProcessCourse from './Components/ProcessCourse/processcourse-component';
import DeleteCourse from './Components/DeleteCourse/deletecourse-component';

const App = function () {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    async function redirect() {
      if (accounts.length === 0 && inProgress === InteractionStatus.None) {
        await instance.loginRedirect();
      } else if (accounts.length === 1) {
        axios.interceptors.request.use(async (config) => {
          const updatedConfig = config;

          const request = {
            account: accounts[0],
          };

          await instance.acquireTokenSilent(request);

          const token = await instance.acquireTokenSilent(request);
          updatedConfig.headers.Authorization = `Bearer ${token.idToken}`;

          return updatedConfig;
        });
      }
    }
    redirect();
  }, [isAuthenticated, inProgress, instance]);

  return !isAuthenticated ? '' : (
    <BrowserRouter>
      <Toaster position="top-right" />
      <div className="App">
        <header className="App-header">
          <Navbar bg="dark" variant="dark">
            <Container>
              <Navbar.Brand>
                <Link to="/" className="nav-link">
                  STEM Learning - ITK Administration
                </Link>
              </Navbar.Brand>
              <Nav>
                <SignOutButton />
              </Nav>
            </Container>
          </Navbar>
        </header>
        <Container>
          <Row>
            <Col md={12}>
              <div className="wrapper">
                <Routes>
                  <Route exact path="/" element={<Dashboard />} />
                  <Route
                    path="/rules"
                    element={(
                      <AdminRouteGuard>
                        <RuleList />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/rules/create-rule"
                    element={(
                      <AdminRouteGuard>
                        <CreateRule />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/rules/update-rule/:id"
                    element={(
                      <AdminRouteGuard>
                        <UpdateRule />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/pathways"
                    element={(
                      <AdminRouteGuard>
                        <PathwayList />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/pathways/create-pathway"
                    element={(
                      <AdminRouteGuard>
                        <CreatePathway />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/pathways/update-pathway/:id"
                    element={(
                      <AdminRouteGuard>
                        <UpdatePathway />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/rulesets"
                    element={(
                      <AdminRouteGuard>
                        <RulesetList />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/rulesets/create-ruleset"
                    element={(
                      <AdminRouteGuard>
                        <CreateRuleset />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/rulesets/update-ruleset/:id"
                    element={(
                      <AdminRouteGuard>
                        <UpdateRuleset />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/rulesets/clone-ruleset/:id"
                    element={(
                      <AdminRouteGuard>
                        <CloneRuleset />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/surveys"
                    element={(
                      <AdminRouteGuard>
                        <SurveyList />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/surveys/create-survey"
                    element={(
                      <AdminRouteGuard>
                        <CreateSurvey />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/surveys/update-survey/:id"
                    element={(
                      <AdminRouteGuard>
                        <UpdateSurvey />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route path="/engagement-history" element={<HistoryList />} />
                  <Route
                    path="/admin"
                    element={(
                      <AdminRouteGuard>
                        <Admin />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/admin/process-course"
                    element={(
                      <AdminRouteGuard>
                        <ProcessCourse />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/admin/delete-course"
                    element={(
                      <AdminRouteGuard>
                        <DeleteCourse />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/admin/bounces"
                    element={(
                      <AdminRouteGuard>
                        <BounceList />
                      </AdminRouteGuard>
                    )}
                  />
                  <Route
                    path="/admin/bounces/resolve/:id"
                    element={(
                      <AdminRouteGuard>
                        <ResolveBounce />
                      </AdminRouteGuard>
                    )}
                  />
                </Routes>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </BrowserRouter>
  );
};

export default App;
