import { React } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SurveyMissing = function (props) {
  const { isSurveyMissing } = props;

  return (
    <div>
      {isSurveyMissing === true
        ? (
          <Alert key="danger" variant="danger" className="form-wrapper">
            <p>We can&apos;t fine the Alchemer ID entered within the Alchemer system, please check the ID and try again.</p>
          </Alert>
        )
        : ''}
    </div>
  );
};
SurveyMissing.propTypes = {
  isSurveyMissing: PropTypes.bool.isRequired,
};

export default SurveyMissing;
