import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import { getAllRulesetsAsync } from '../../Helpers/API/RulesetApi';
import RulesetTableRow from './RulesetTableRow';

const RulesetList = function () {
  const [activeRulesets, setActiveRulesets] = useState([]);
  const [deletedRulesets, setDeletedRulesets] = useState([]);

  useEffect(() => {
    async function getRulesets() {
      const data = await getAllRulesetsAsync();
      setActiveRulesets(data.filter((ruleset) => !ruleset.isDeleted && !ruleset.isExpired));
      setDeletedRulesets(data.filter((ruleset) => ruleset.isDeleted || ruleset.isExpired));
    }
    getRulesets();
  }, []);

  const deleteRow = (id) => {
    const activeRulesetRows = [...activeRulesets];

    const deletedRuleset = activeRulesetRows.filter((ruleset) => ruleset.id === id)[0];
    deletedRuleset.isDeleted = true;

    setActiveRulesets(activeRulesetRows.filter((ruleset) => ruleset.id !== id));

    const deletedRulesetRows = [...deletedRulesets];
    deletedRulesetRows.push(deletedRuleset);
    setDeletedRulesets(deletedRulesetRows.sort((a, b) => a.name.localeCompare(b.name)));
  };

  const ActiveRulesetsTable = () => activeRulesets.map((ruleset) => <RulesetTableRow ruleset={ruleset} key={ruleset.id} deleteRow={deleteRow} />);
  const DeletedRulesetsTable = () => deletedRulesets.map((ruleset) => <RulesetTableRow ruleset={ruleset} key={ruleset.id} />);

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rulesets</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Rulesets</h4>
      <div className="table-wrapper">
        <Link to="/rulesets/create-ruleset/" className="header-link btn">
          Create a new ruleset
        </Link>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Order</th>
              <th className="d-none d-sm-table-cell">Source System</th>
              <th className="d-none d-sm-table-cell">Expiry Date</th>
              <th className="grid-actions-header-large">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{ActiveRulesetsTable()}</tbody>
        </Table>
      </div>
      <div className="deactivated-rulesets">
        <h5>Deactivated Rulesets</h5>
        <div className="table-wrapper">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Order</th>
                <th className="d-none d-sm-table-cell">Source System</th>
                <th className="d-none d-sm-table-cell">Expiry Date</th>
                <th className="grid-actions-header-small">&nbsp;</th>
              </tr>
            </thead>
            <tbody>{DeletedRulesetsTable()}</tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default RulesetList;
