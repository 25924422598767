import { React } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ConflictError = function (props) {
  const {
    primaryEntity, secondaryEntity, isConflicted, conflicts,
  } = props;

  return (
    <div>
      {isConflicted === true
        ? (
          <Alert key="danger" variant="danger">
            <p>
              Cannot delete a&nbsp;
              {primaryEntity}
              &nbsp;which is in use by active&nbsp;
              {secondaryEntity}
              . The selected&nbsp;
              {primaryEntity}
              &nbsp;is used in&nbsp;
              {secondaryEntity}
              :
            </p>
            <ul>{conflicts}</ul>
          </Alert>
        )
        : ''}
    </div>
  );
};
ConflictError.propTypes = {
  primaryEntity: PropTypes.string.isRequired,
  secondaryEntity: PropTypes.string.isRequired,
  isConflicted: PropTypes.bool.isRequired,
  conflicts: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ConflictError;
