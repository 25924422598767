import { React } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { deleteRulesetAsync } from '../../Helpers/API/RulesetApi';
import { convertSourceSystemToText } from '../../Helpers/SelectValues/SourceSystems';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const RulesetTableRow = function (props) {
  const { ruleset, deleteRow } = props;

  const deleteRulesetOnClick = (name) => {
    async function deleteRuleset(deactivateEngagements) {
      const success = await deleteRulesetAsync(ruleset.id, deactivateEngagements);
      if (success) deleteRow(ruleset.id);
    }

    ConfirmDialog({
      entityName: name,
      onDelete: deleteRuleset,
      showToggleOption: true,
      toggleOptionText: 'Do you want to delete all unsent surveys for this ruleset?',
    });
  };

  return (
    <tr>
      <td>{ruleset.name}</td>
      <td>{ruleset.order}</td>
      <td className="d-none d-sm-table-cell">{convertSourceSystemToText(ruleset.sourceSystem)}</td>
      <td className="d-none d-sm-table-cell">
        {
            ruleset.expiryDate === '' || ruleset.expiryDate === null
              ? ''
              : moment(ruleset.expiryDate).format('DD/MM/yyyy')
          }
      </td>
      <td>
        {!ruleset.isDeleted && !ruleset.isExpired && (
          <Link to={`/rulesets/update-ruleset/${ruleset.id}`} className="update-link">
            Update
          </Link>
        )}
        <Link to={`/rulesets/clone-ruleset/${ruleset.id}`} className="update-link">
          Clone
        </Link>
        {!ruleset.isDeleted && !ruleset.isExpired && (
          <Button onClick={() => deleteRulesetOnClick(ruleset.name)} size="sm" variant="danger">
            Delete
          </Button>
        )}
      </td>
    </tr>
  );
};
RulesetTableRow.propTypes = {
  ruleset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    sourceSystem: PropTypes.string.isRequired,
    isDeleted: PropTypes.bool.isRequired,
    isExpired: PropTypes.bool.isRequired,
    expiryDate: PropTypes.string,
  }).isRequired,
  deleteRow: PropTypes.func,
};
RulesetTableRow.defaultProps = {
  deleteRow: () => {},
};

export default RulesetTableRow;
