import { React } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import RulesetForm from './RulesetForm';
import { createRulesetAsync } from '../../Helpers/API/RulesetApi';

const CreateRuleset = function () {
  const navigate = useNavigate();
  const formValues = {
    name: '', sourceSystem: '', pathwayId: '', order: 0, rules: [], expiryDate: '', deactivateSurveysOnExpiry: true,
  };

  const onSubmit = (rulesetObject) => {
    async function createRuleset() {
      const ruleset = rulesetObject;

      if (ruleset.expiryDate === '' || ruleset.expiryDate === null || ruleset.expiryDate === undefined) {
        ruleset.expiryDate = null;
        ruleset.deactivateSurveysOnExpiry = null;
      }

      const success = await createRulesetAsync(ruleset);
      if (success) navigate('/rulesets');
    }
    createRuleset();
  };

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/rulesets' }}>Rulesets</Breadcrumb.Item>
          <Breadcrumb.Item active>New Ruleset</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <RulesetForm initialValues={formValues} saveText="Create ruleset" headerText="Create a new ruleset" onSubmit={onSubmit} isUpdateMode={false} />
    </div>
  );
};

export default CreateRuleset;
