import toast from 'react-hot-toast';
import axios from 'axios';

const ApiRoot = `${process.env.REACT_APP_RULES_API_ROOT}/programme`;

async function getAllProgrammesAsync() {
  try {
    const result = await axios.get(ApiRoot);
    return result.data;
  } catch {
    toast.error('Error listing programmes please try again');
    return [];
  }
}

export default getAllProgrammesAsync;
