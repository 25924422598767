/* eslint-disable react/no-array-index-key */
import { React, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Formik, Form, Field, ErrorMessage, FieldArray,
} from 'formik';
import { FormGroup, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ApplyDeltaFromSelect } from '../../Helpers/SelectValues/ApplyDeltaFrom';
import SurveysSelect from '../../Helpers/SelectValues/Surveys';
import { getAllSurveysAsync } from '../../Helpers/API/SurveyApi';

const PathwayForm = function (props) {
  const [surveys, setSurveys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getSurveys() {
      const allSurveys = await getAllSurveysAsync();
      setSurveys(allSurveys);
    }
    getSurveys();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name'),
    surveys: Yup.array()
      .of(Yup.object().shape({
        id: Yup.string().required('Please select a survey'),
        dayDelta: Yup.number().required('Please enter a day delta'),
        applyDeltaFrom: Yup.string().required('Please select a from'),
      })),
  });

  const {
    saveText, headerText, initialValues, onSubmit,
  } = props;

  const onCancel = () => {
    navigate('/pathways');
  };

  return (
    <div className="form-wrapper">
      <h4>{headerText}</h4>
      <Formik initialValues={initialValues} enableReinitialize="true" validationSchema={validationSchema} validateOnMount="true" onSubmit={onSubmit}>
        {({
          isValid, values,
        }) => (
          <Form>
            <FormGroup className="form-group">
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" type="text" className="form-control" />
              <ErrorMessage name="name" className="d-block invalid-feedback" component="span" />
            </FormGroup>
            <FormGroup className="form-group">
              <FieldArray name="surveys">
                {({ remove, push }) => (
                  <div className="table-wrapper">
                    <Button
                      variant="primary"
                      block="block"
                      type="button"
                      className="create-btn header-link"
                      onClick={() => {
                        push({ id: '', dayDelta: 0 });
                      }}
                    >
                      Add survey
                    </Button>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Survey</th>
                          <th>Day Delta</th>
                          <th>From</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          values.surveys.map((_, index) => (
                            <tr key={index}>
                              <td>
                                <SurveysSelect
                                  id={`surveys.${index}.id`}
                                  name={`surveys.${index}.id`}
                                  surveys={surveys}
                                  isNewRow={values.surveys[index].id === ''}
                                  showLabel={false}
                                />
                              </td>
                              <td>
                                <Field
                                  id={`surveys.${index}.dayDelta`}
                                  name={`surveys.${index}.dayDelta`}
                                  type="number"
                                  placeholder="Day Delta"
                                  className="form-control"
                                />
                                <ErrorMessage name={`surveys.${index}.dayDelta`} className="d-block invalid-feedback" component="span" />
                              </td>
                              <td>
                                <ApplyDeltaFromSelect index={index} />
                              </td>
                              <td>
                                <Button onClick={() => remove(index)} size="sm" variant="danger">
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
                )}
              </FieldArray>
            </FormGroup>
            <div className="form-submission">
              <Button variant="primary" block="block" type="submit" className="create-btn" disabled={!isValid}>
                {saveText}
              </Button>
              <Button variant="danger" block="block" type="button" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
PathwayForm.propTypes = {
  saveText: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surveys: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      dayDelta: PropTypes.number.isRequired,
      applyDeltaFrom: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PathwayForm;
