import toast from 'react-hot-toast';
import axios from 'axios';

const EngagementApiRoot = `${process.env.REACT_APP_ENGAGEMENT_API_ROOT}`;
const RulesApiRoot = `${process.env.REACT_APP_RULES_API_ROOT}`;

export async function invokeGetScheduledEngagements() {
  try {
    await axios.get(`${EngagementApiRoot}/scheduledengagements`);
    toast.success('Invoked get scheduled engagements');
  } catch {
    toast.error('Error invoking get scheduled engagements please try again');
  }
}

export async function invokeDynamicsDataPull() {
  try {
    await axios.get(`${RulesApiRoot}/processcourses`);
    toast.success('Invoked dynamics data pull');
  } catch {
    toast.error('Error invoking dynamics data pull please try again');
  }
}

export async function invokeExpireRulesets() {
  try {
    await axios.get(`${RulesApiRoot}/expirerulesets`);
    toast.success('Invoked expire rulesets');
  } catch {
    toast.error('Error invoking expire rulesets please try again');
  }
}

export async function invokePopulateMissingDrupalIds() {
  try {
    await axios.get(`${EngagementApiRoot}/populate-missing-drupal-ids`);
    toast.success('Invoked populate missing Drupal user ID\'s');
  } catch {
    toast.error('Error invoking populate missing Drupal user ID\'s please try again');
  }
}

export async function invokeProcessCourseOccurance(courseOccuranceId) {
  let success = true;

  try {
    await axios.post(`${RulesApiRoot}/course/${courseOccuranceId}`);
    toast.success('Began processing for the supplied course occurance ID');
  } catch (error) {
    success = false;

    if (error.response?.status === 404) {
      toast.error('The provided course occurance ID could not be found in Dynamics');
    } else {
      toast.error('Error processing the supplied course occurance ID, please try again');
    }
  }

  return success;
}
