import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';

function handleLogout(instance) {
  instance.logoutRedirect();
}

const SignOutButton = function () {
  const { instance } = useMsal();

  return (
    <Button variant="primary" className="ml-auto" onClick={() => handleLogout(instance)}>Sign out</Button>
  );
};

export default SignOutButton;
