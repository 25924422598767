/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState, useMemo,
} from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import moment from 'moment';
import searchHistory from '../../Helpers/API/HistoryApi';
import HistoryModel from './HistoryModel';
import HistorySearch from './HistorySearch';
import HistoryPagination from './HistoryPagination';

const HistoryList = function () {
  const [history, setHistory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const handleClose = () => setShowModal(false);

  const handleShow = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email Address',
        accessor: 'emailAddress',
        className: 'd-none d-sm-table-cell',
      },
      {
        Header: 'Course Code',
        accessor: 'activityCode',
        className: 'd-none d-sm-table-cell',
      },
      {
        Header: 'Instance Code',
        accessor: 'instanceCode',
        className: 'd-none d-sm-table-cell',
      },
      {
        Header: 'Course Start Date',
        accessor: 'courseStartDate',
        className: 'd-none d-sm-table-cell',
        Cell: ({ value }) => (moment(value).format('DD/MM/yyyy')),
      },
      {
        Header: 'Survey',
        accessor: 'surveyName',
        className: 'd-none d-sm-table-cell',
      },
      {
        Header: 'Send On',
        accessor: 'sendSurveyOn',
        className: 'd-none d-sm-table-cell',
        Cell: ({ value }) => (moment(value).format('DD/MM/yyyy')),
      },
      {
        Header: 'Is Sent?',
        accessor: 'isSurveySent',
        className: 'd-none d-sm-table-cell',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: 'Response Received?',
        accessor: 'isResponseReceived',
        className: 'd-none d-sm-table-cell',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: '',
        id: 'action-link',
        // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
        Cell: ({ row }) => (
          // eslint-disable-next-line react/prop-types
          <Button variant="primary" size="sm" onClick={() => handleShow(row.original)}>
            View Details
          </Button>
        ),
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: history,
      initialState: {
        sortBy: [
          {
            id: 'sendSurveyOn',
            desc: false,
          },
        ],
        pageIndex: 0,
        pageSize: 20,
      },
    },
    useSortBy,
    usePagination,
  );

  const showSortIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return ' 🔽';
      }

      return ' 🔼';
    }

    return '';
  };

  const initialSearchValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    activityCode: '',
    instanceCode: '',
    courseStartDate: '',
    sendOn: '',
    survey: '',
  };

  const onSubmit = async (searchCriteria) => {
    const data = await searchHistory(
      searchCriteria.firstName,
      searchCriteria.lastName,
      searchCriteria.emailAddress,
      searchCriteria.activityCode,
      searchCriteria.instanceCode,
      searchCriteria.courseStartDate,
      searchCriteria.sendOn,
      searchCriteria.isSent,
      '',
      searchCriteria.survey,
    );
    setHistory(data);
    setNoRecordsFound(data.length === 0);
  };

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Engagement History</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Engagement History</h4>
      <div className="FormWrapper history-search">
        <HistorySearch onSubmit={onSubmit} initialValues={initialSearchValues} />
      </div>
      <div className="table-wrapper">
        <HistoryModel show={showModal} handleClose={handleClose} history={selectedRow} />
        <Table striped bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps([column.getSortByToggleProps(), { className: column.className }])}>
                    {column.render('Header')}
                    <span>
                      {showSortIcon(column)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {noRecordsFound
              ? (
                <tr>
                  <td colSpan={10}>
                    <span className="d-block invalid-feedback">
                      No engagement records were found
                    </span>
                  </td>
                </tr>
              )
              : (
                page.map(
                  (row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {
                          row.cells.map((cell) => (
                            <td {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</td>
                          ))
                        }
                      </tr>
                    );
                  },
                )
              )}
          </tbody>
        </Table>
        <HistoryPagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
        />
      </div>
    </div>
  );
};

export default HistoryList;
