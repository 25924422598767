import { React, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { createSurveyAsync } from '../../Helpers/API/SurveyApi';
import SurveyForm from './SurveyForm';
import SurveyMissing from './SurveyMissing';

const CreateSurvey = function () {
  const [surveyMissing, setSurveyMissing] = useState(false);

  const navigate = useNavigate();
  const formValues = {
    name: '', alchemerId: '', emailTemplate: '',
  };

  const onSubmit = (surveyObject) => {
    async function createSurvey() {
      const { success, isSurveyMissing } = await createSurveyAsync(surveyObject);
      if (success) navigate('/surveys');
      setSurveyMissing(isSurveyMissing);
    }
    createSurvey();
  };

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/surveys' }}>Surveys</Breadcrumb.Item>
          <Breadcrumb.Item active>New Survey</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <SurveyMissing isSurveyMissing={surveyMissing} />
      <SurveyForm initialValues={formValues} saveText="Create survey" headerText="Create a new survey" onSubmit={onSubmit} />
    </div>

  );
};

export default CreateSurvey;
