import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const sourceSystems = [
  { id: 'dynamics', text: 'Dynamics' },
  { id: 'nuffield', text: 'Nuffield' },
];

export function convertSourceSystemToText(sourceSystemId) {
  const result = sourceSystems.find((sourceSystem) => sourceSystem.id === sourceSystemId);
  return result == null ? 'Unknown' : result.text;
}

export function SourceSystemSelect(props) {
  const { isUpdateMode } = props;

  const sourceSystemOptions = sourceSystems.map((sourceSystem) => (
    <option value={sourceSystem.id} key={sourceSystem.id}>
      {sourceSystem.text}
    </option>
  ));

  return (
    <span>
      <label htmlFor="sourceSystem">Source System</label>
      <Field name="sourceSystem" as="select" className="form-control" disabled={isUpdateMode}>
        <option value="">Select a source system</option>
        {sourceSystemOptions}
      </Field>
      <ErrorMessage name="sourceSystem" className="d-block invalid-feedback" component="span" />
    </span>
  );
}
SourceSystemSelect.propTypes = {
  isUpdateMode: PropTypes.bool.isRequired,
};
