import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getAllSurveysAsync } from '../../Helpers/API/SurveyApi';
import ConflictError from '../ConflictError/ConflictError';
import SurveyTableRow from './SurveyTableRow';

const SurveyList = function () {
  const [surveys, setSurveys] = useState([]);
  const [isConflicted, setIsConflicted] = useState(false);
  const [conflicts, setConflicts] = useState([]);

  useEffect(() => {
    async function getSurveys() {
      const data = await getAllSurveysAsync();
      setSurveys(data);
    }
    getSurveys();
  }, []);

  const deleteRow = (id) => {
    const rows = [...surveys];
    setSurveys(rows.filter((survey) => survey.id !== id));
  };

  const showConflictNotification = (conflicted) => {
    setIsConflicted(true);
    setConflicts(conflicted.map((conflictedItem) => <li key={conflictedItem}>{conflictedItem}</li>));
  };

  const SurveysTable = () => surveys.map(
    (survey) => <SurveyTableRow survey={survey} key={survey.id} deleteRow={deleteRow} showConflictNotification={showConflictNotification} />,
  );

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Surveys</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Surveys</h4>
      <div className="table-wrapper">
        <ConflictError primaryEntity="survey" secondaryEntity="pathways" isConflicted={isConflicted} conflicts={conflicts} />
        <Link to="/surveys/create-survey/" className="header-link btn">
          Create a new survey
        </Link>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th className="d-none d-sm-table-cell">Alchemer ID</th>
              <th className="d-none d-sm-table-cell">Mandrill Template</th>
              <th className="grid-actions-header-large">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{SurveysTable()}</tbody>
        </Table>
      </div>
    </div>

  );
};

export default SurveyList;
