import { React, useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import searchHistory from '../../Helpers/API/HistoryApi';
import BounceTableRow from './BounceTableRow';

const BounceList = function () {
  const [bounces, setBounces] = useState([]);

  useEffect(() => {
    async function getBounces() {
      const data = await searchHistory(
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        true,
        '',
      );
      setBounces(data);
    }
    getBounces();
  }, []);

  const BouncesTable = () => bounces.map(
    (engagement) => <BounceTableRow engagement={engagement} key={engagement.id} />,
  );

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
            Admin Tools
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Bounced Emails</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Bounced Emails</h4>
      <div className="table-wrapper">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Email Address</th>
              <th className="d-none d-sm-table-cell">Bounce Type</th>
              <th className="d-none d-sm-table-cell">Name</th>
              <th className="d-none d-sm-table-cell">Survey</th>
              <th className="d-none d-sm-table-cell">Attempted On</th>
              <th className="grid-actions-header-medium">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{BouncesTable()}</tbody>
        </Table>
      </div>
    </div>

  );
};

export default BounceList;
