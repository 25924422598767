import { React, useState, useEffect } from 'react';
import * as Yup from 'yup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import {
  Alert, Col, Row, FormGroup, Button, Table,
} from 'react-bootstrap';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { deleteEngagements, getEngagementPreview } from '../../Helpers/API/EngagementApi';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import EngagementPreviewTableRow from './EngagementPreviewTableRow';

const DeleteCourse = function () {
  const [engagementsToDelete, setEngagementsToDelete] = useState([]);
  const [engagementsToRetain, setEngagementsToRetain] = useState([]);
  const [inPreviewMode, setInPreviewMode] = useState(false);
  const [courseOccuranceId, setCourseOccuranceId] = useState('');
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  useEffect(() => {
  }, [courseOccuranceId]);

  const initialValues = {
    courseOccuranceId: '',
  };

  const validationSchema = Yup.object().shape({
    courseOccuranceId: Yup.string().required('Please enter an course occurrence ID'),
  });

  const onPreviewSubmit = async (searchCriteria) => {
    setEngagementsToDelete([]);
    setEngagementsToRetain([]);

    setCourseOccuranceId(searchCriteria.courseOccuranceId);

    const engagementRecords = await getEngagementPreview(searchCriteria.courseOccuranceId);

    setEngagementsToDelete(engagementRecords.filter((engagement) => !engagement.isSurveySent));
    setEngagementsToRetain(engagementRecords.filter((engagement) => engagement.isSurveySent));

    if (engagementRecords.length === 0) setNoRecordsFound(true);
    setInPreviewMode(true);
  };

  const resetForm = () => {
    setNoRecordsFound(false);
    setInPreviewMode(false);
    setCourseOccuranceId('');
  };

  const onDeleteSubmit = async () => {
    async function deleteRecords() {
      await deleteEngagements(courseOccuranceId);
      resetForm();
    }

    ConfirmDialog({
      operation: 'delete',
      entityName: 'these engagements',
      confirmationText: 'Delete them',
      onDelete: deleteRecords,
    });
  };

  const onCancel = () => {
    resetForm();
  };

  const DeletePreviewTable = () => engagementsToDelete.map(
    (engagement) => <EngagementPreviewTableRow key={engagement.id} engagement={engagement} />,
  );

  const RetainPreviewTable = () => engagementsToRetain.map(
    (engagement) => <EngagementPreviewTableRow key={engagement.id} engagement={engagement} />,
  );

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
            Admin Tools
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Delete Course Occurrence</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Delete Course Occurrence</h4>
      <Alert key="danger" variant="primary" className="form-wrapper">
        <p>
          If the course is still within the processing window for ITK, the engagement records will
          be recreated the next time data is pulled from Dynamics.
        </p>
      </Alert>
      <Formik onSubmit={onPreviewSubmit} initialValues={initialValues} enableReinitialize="true" validationSchema={validationSchema} validateOnMount="true">
        {({
          isValid,
        }) => (
          <Form>
            <Row>
              <FormGroup as={Col} className="form-group col-md-6 col-12">
                <label htmlFor="courseOccuranceId">Course occurrence ID</label>
                <Field id="courseOccuranceId" name="courseOccuranceId" type="text" className="form-control" />
                <ErrorMessage name="courseOccuranceId" className="d-block invalid-feedback" component="span" />
              </FormGroup>
            </Row>
            <div className="form-submission">
              <Button variant="primary" block="block" type="submit" className="create-btn" disabled={!isValid}>
                Preview Engagements
              </Button>
            </div>
            {inPreviewMode
              ? (
                <div>
                  <div>
                    <Alert key="danger" variant="danger" className="form-wrapper">
                      <p>
                        The following engagements have not been sent and will be removed.
                      </p>
                    </Alert>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="d-none d-sm-table-cell">Email Address</th>
                          <th className="d-none d-sm-table-cell">Survey</th>
                          <th className="d-none d-sm-table-cell">Send On</th>
                          <th className="d-none d-sm-table-cell">Is Sent</th>
                        </tr>
                      </thead>
                      <tbody>
                        {engagementsToDelete.length === 0
                          ? (
                            <tr>
                              <td colSpan={5}>
                                <span className="d-block invalid-feedback">
                                  No records were found
                                </span>
                              </td>
                            </tr>
                          )
                          : (
                            DeletePreviewTable()
                          )}
                      </tbody>
                    </Table>
                  </div>
                  {engagementsToRetain.length > 0
                    ? (
                      <div>
                        <Alert key="danger" variant="warning" className="form-wrapper">
                          <p>
                            The following engagements have been sent and will be retained in the database.
                          </p>
                        </Alert>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th className="d-none d-sm-table-cell">Email Address</th>
                              <th className="d-none d-sm-table-cell">Survey</th>
                              <th className="d-none d-sm-table-cell">Send On</th>
                              <th className="d-none d-sm-table-cell">Is Sent</th>
                            </tr>
                          </thead>
                          <tbody>
                            {noRecordsFound
                              ? (
                                <tr>
                                  <td colSpan={5}>
                                    <span className="d-block invalid-feedback">
                                      No records were found for&nbsp;
                                      {courseOccuranceId}
                                    </span>
                                  </td>
                                </tr>
                              )
                              : (
                                RetainPreviewTable()
                              )}
                          </tbody>
                        </Table>
                      </div>
                    )
                    : null}
                  <div className="form-submission">
                    <Button
                      variant="primary"
                      block="block"
                      type="button"
                      className="create-btn"
                      disabled={engagementsToDelete.length === 0}
                      onClick={onDeleteSubmit}
                    >
                      Delete Engagements
                    </Button>
                    <Button variant="danger" block="block" type="button" onClick={onCancel}>
                      Cancel
                    </Button>
                  </div>
                </div>
              )
              : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DeleteCourse;
