import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import getAllProgrammesAsync from '../API/ProgrammeApi';
import getAllItkCpdTypesAsync from '../API/ItkCpdTypesApi';
import getAllWorkstreamsAsync from '../API/WorkstreamApi';

export const dynamicsProgrammes = 'programmes';
export const dynamicsWorkstreams = 'workstreams';
export const dynamicsItkCpdTypes = 'itkcpdtypes';

export const DynamicsLookupSelect = function (props) {
  const {
    type, name, label, placeholder, setSelectedValueText,
  } = props;
  const [values, setValues] = useState([]);

  const formikProps = useFormikContext();

  useEffect(() => {
    async function getProgrammes() {
      const allProgrammes = await getAllProgrammesAsync();
      setValues(allProgrammes);
    }

    async function getItkCpdTypes() {
      const allItkCpdTypes = await getAllItkCpdTypesAsync();
      setValues(allItkCpdTypes);
    }

    async function getWorkStreams() {
      const allWorkstreams = await getAllWorkstreamsAsync();
      setValues(allWorkstreams);
    }

    switch (type) {
      case dynamicsProgrammes:
        getProgrammes();
        break;
      case dynamicsWorkstreams:
        getWorkStreams();
        break;
      case dynamicsItkCpdTypes:
        getItkCpdTypes();
        break;
      default:
        setValues([]);
    }
  }, []);

  const selectOptions = values.map((value) => (
    <option value={value.id} key={value.id}>
      {value.name}
    </option>
  ));

  const onChange = (selectedValue) => {
    formikProps.setFieldValue(name, selectedValue);
    const selectedName = (values.filter((value) => value.id === selectedValue))[0].name;
    setSelectedValueText(selectedName);
  };

  return (
    <span>
      <label htmlFor={name}>{label}</label>
      <Field name={name} as="select" className="form-control" onChange={(e) => onChange(e.target.value)}>
        <option value="">{placeholder}</option>
        {selectOptions}
      </Field>
      <ErrorMessage name={name} className="d-block invalid-feedback" component="span" />
    </span>
  );
};
DynamicsLookupSelect.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setSelectedValueText: PropTypes.func.isRequired,
};
