import { React, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  FormGroup, Row, Col, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import SurveysSelect from '../../Helpers/SelectValues/Surveys';
import { getAllSurveysAsync } from '../../Helpers/API/SurveyApi';

const HistorySearch = function (props) {
  const [surveys, setSurveys] = useState([]);
  const { onSubmit, initialValues } = props;

  useEffect(() => {
    async function getSurveys() {
      const allSurveys = await getAllSurveysAsync();
      setSurveys(allSurveys);
    }
    getSurveys();
  }, []);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      <Form>
        <Row>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="firstName">First name</label>
            <Field id="firstName" name="firstName" type="text" className="form-control" />
          </FormGroup>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="lastName">Last name</label>
            <Field id="lastName" name="lastName" type="text" className="form-control" />
          </FormGroup>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="emailAddress">Email address</label>
            <Field id="emailAddress" name="emailAddress" type="text" className="form-control" />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="activityCode">Course code</label>
            <Field id="activityCode" name="activityCode" type="text" className="form-control" />
          </FormGroup>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="instanceCode">Instance code</label>
            <Field id="instanceCode" name="instanceCode" type="text" className="form-control" />
          </FormGroup>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="courseStartDate">Course start date</label>
            <Field id="courseStartDate" name="courseStartDate" type="date" className="form-control" />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <SurveysSelect id="survey" name="survey" surveys={surveys} isNewRow showLabel />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup as={Col} className="form-group col-md-6 col-12">
            <label htmlFor="sendOn">Send on</label>
            <Field id="sendOn" name="sendOn" type="date" className="form-control" />
          </FormGroup>
          <FormGroup as={Col} className="form-group col-md-6 col-12">
            <label htmlFor="isSent">Is sent?</label>
            <Field id="isSent" name="isSent" type="checkbox" className="form-check" />
          </FormGroup>
        </Row>
        <div className="form-submission">
          <Button variant="primary" block="block" type="submit">
            Search
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
HistorySearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    sendOn: PropTypes.string.isRequired,
  }).isRequired,
};

export default HistorySearch;
