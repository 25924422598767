import toast from 'react-hot-toast';
import axios from 'axios';

const ApiRoot = `${process.env.REACT_APP_RULES_API_ROOT}/rule`;

export async function getAllRulesAsync() {
  try {
    const result = await axios.get(ApiRoot);
    return result.data;
  } catch {
    toast.error('Error listing rules please try again');
    return [];
  }
}

export async function getRuleByIdAsync(id) {
  try {
    const result = await axios.get(`${ApiRoot}/${id}`);
    return result.data;
  } catch {
    toast.error('Error loading rule please try again');
    return {};
  }
}

export async function createRuleAsync(rule) {
  let success = true;

  try {
    const result = await axios.post(ApiRoot, rule);
    if (result.status !== 201) success = false;
  } catch {
    success = false;
  }

  if (success) toast.success('Rule created successfully');
  else toast.error('Error creating rule please try again');

  return success;
}

export async function updateRuleAsync(id, rule) {
  let success = true;

  try {
    const result = await axios.put(`${ApiRoot}/${id}`, rule);
    if (result.status !== 200) success = false;
  } catch {
    success = false;
  }

  if (success) toast.success('Rule updated successfully');
  else toast.error('Error updating rule please try again');

  return success;
}

export async function deleteRuleAsync(id) {
  let success = true;
  let isConflicted = false;
  let conflicts = [];

  try {
    const result = await axios.delete(`${ApiRoot}/${id}`);
    if (result.status !== 204) success = false;
  } catch (exception) {
    if (exception.response.status === 409) {
      isConflicted = true;
      conflicts = exception.response.data;
    }

    success = false;
  }

  if (success) toast.success('Rule deleted successfully');
  else toast.error('Error deleting rule please try again');

  return { success, isConflicted, conflicts };
}
