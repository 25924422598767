import toast from 'react-hot-toast';
import axios from 'axios';

const ApiRoot = `${process.env.REACT_APP_RULES_API_ROOT}/ruleset`;

export async function getAllRulesetsAsync() {
  try {
    const result = await axios.get(ApiRoot);
    return result.data;
  } catch {
    toast.error('Error listing rulesets please try again');
    return [];
  }
}

export async function getRulesetByIdAsync(id) {
  try {
    const result = await axios.get(`${ApiRoot}/${id}`);
    return result.data;
  } catch {
    toast.error('Error loading ruleset please try again');
    return {};
  }
}

export async function createRulesetAsync(ruleset) {
  let success = true;

  try {
    const result = await axios.post(ApiRoot, ruleset);
    if (result.status !== 201) success = false;
  } catch {
    success = false;
  }

  if (success) toast.success('Ruleset created successfully');
  else toast.error('Error creating ruleset please try again');

  return success;
}

export async function updateRulesetAsync(id, pathway) {
  let success = true;

  try {
    const result = await axios.put(`${ApiRoot}/${id}`, pathway);
    if (result.status !== 200) success = false;
  } catch {
    success = false;
  }

  if (success) toast.success('Ruleset updated successfully');
  else toast.error('Error updating ruleset please try again');

  return success;
}

export async function deleteRulesetAsync(id, deactivateEngagements) {
  let success = true;

  try {
    const result = await axios.delete(`${ApiRoot}/${id}?deactivateEngagements=${deactivateEngagements}`);
    if (result.status !== 204) success = false;
  } catch {
    success = false;
  }

  if (success) toast.success('Ruleset deleted successfully');
  else toast.error('Error deleting ruleset please try again');

  return success;
}
