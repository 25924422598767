import { React } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { resendEmail } from '../../Helpers/API/EngagementApi';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const HistoryModel = function (props) {
  const { history, show, handleClose } = props;

  const onResendEmail = async () => {
    async function resendTheEmail() {
      const success = await resendEmail(history.id);
      if (success) handleClose();
    }

    ConfirmDialog({
      operation: 'resend',
      entityName: `${history.surveyName} to ${history.emailAddress}`,
      confirmationText: 'resend it',
      onDelete: resendTheEmail,
    });
  };

  return (
    <Modal show={show} onHide={handleClose} className="modal-window">
      <Modal.Header closeButton>
        <Modal.Title>Engagement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>First Name:</b>
          <br />
          {history?.firstName}
        </p>
        <p>
          <b>Last Name:</b>
          <br />
          {history?.lastName}
        </p>
        <p>
          <b>Email Address:</b>
          <br />
          {history?.emailAddress}
        </p>
        <p>
          <b>Course Code:</b>
          <br />
          {history?.activityCode}
        </p>
        <p>
          <b>Instance Code:</b>
          <br />
          {history?.instanceCode}
        </p>
        <p>
          <b>Course Start Date:</b>
          <br />
          {moment(history?.courseStartDate).format('DD/MM/yyyy')}
        </p>
        <p>
          <b>Survey:</b>
          <br />
          {history?.surveyName}
        </p>
        <p>
          <b>Send Survey On:</b>
          <br />
          {moment(history?.sendSurveyOn).format('DD/MM/yyyy')}
        </p>
        <p>
          <b>Is Sent:</b>
          <br />
          {
            history?.isSurveySent
              ? `Yes - ${moment(history?.surveySentOn).format('DD/MM/yyyy')} @ ${moment(history?.surveySentOn).format('HH:mm:ss')}`
              : 'No'
          }
        </p>
        <p>
          <b>Has Bounced:</b>
          <br />
          {
            history?.hasEmailBounced
              ? 'Yes'
              : 'No'
          }
        </p>
        <p className="url-container">
          <b>Survey URL:</b>
          <br />
          {
            history?.surveyUri === ''
              ? 'N/A'
              : <a href={history?.surveyUri} title="Survey URL" target="_blank" rel="noreferrer">{history?.surveyUri}</a>
          }
        </p>
        <p>
          <b>Response Received:</b>
          <br />
          {
            history?.isResponseReceived
              ? `Yes - ${moment(history?.responseReceivedOn).format('DD/MM/yyyy')}`
              : 'No'
          }
        </p>
        {
          history?.isSurveySent
            ? (
              <div className="modal-options">
                <Button variant="danger" block="block" type="button" onClick={onResendEmail}>
                  Resend survey email
                </Button>
              </div>
            )
            : ''
        }

      </Modal.Body>
    </Modal>
  );
};
HistoryModel.defaultProps = {
  history: null,
};
HistoryModel.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailAddress: PropTypes.string,
    activityCode: PropTypes.string,
    instanceCode: PropTypes.string,
    courseStartDate: PropTypes.string,
    surveyName: PropTypes.string,
    sendSurveyOn: PropTypes.string,
    isSurveySent: PropTypes.bool,
    surveySentOn: PropTypes.string,
    surveyUri: PropTypes.string,
    hasEmailBounced: PropTypes.bool,
    isResponseReceived: PropTypes.bool,
    responseReceivedOn: PropTypes.string,
  }),
};

export default HistoryModel;
