import toast from 'react-hot-toast';
import axios from 'axios';

const ApiRoot = `${process.env.REACT_APP_ENGAGEMENT_API_ROOT}/bounce`;

export async function getBounce(id) {
  try {
    const result = await axios.get(`${ApiRoot}/${id}`);
    return result.data;
  } catch {
    toast.error('Error loading bounced email details please try again');
    return {};
  }
}

export async function updateBouncedEmail(currentEmailAddress, newEmailAddress) {
  let success = true;

  try {
    const result = await axios.put(`${ApiRoot}/email/${currentEmailAddress}`, newEmailAddress);
    if (result.status !== 200) success = false;
  } catch {
    success = false;
  }

  if (success) toast.success('An attempt to resend the email will take place shortly');
  else toast.error('Error resending email please try again');

  return success;
}
