import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import RuleForm from './RuleForm';
import { getRuleByIdAsync, updateRuleAsync } from '../../Helpers/API/RuleApi';

const UpdateRule = function () {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValues, setFormValues] = useState({
    name: '',
    field: '',
    operator: '',
    value: '',
    displayValue: '',
  });

  const onSubmit = (ruleObject) => {
    async function updateRule() {
      const success = await updateRuleAsync(id, ruleObject);
      if (success) navigate('/rules');
    }
    updateRule();
  };

  useEffect(() => {
    async function getRule() {
      const {
        name, field, operator, value, displayValue,
      } = await getRuleByIdAsync(id);
      setFormValues({
        name, field, operator, value, displayValue,
      });
    }
    getRule();
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/rules' }}>Rules</Breadcrumb.Item>
          <Breadcrumb.Item active>Update Rule</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <RuleForm initialValues={formValues} saveText="Update rule" headerText="Update an existing rule" onSubmit={onSubmit} />
    </div>
  );
};

export default UpdateRule;
