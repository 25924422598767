/* eslint-disable react/jsx-boolean-value */
import { React } from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import 'react-confirm-alert/src/react-confirm-alert.css';

const ConfirmDialog = function (props) {
  const {
    entityName,
    operation = 'delete',
    confirmationText = 'Delete it',
    onDelete,
    closeBeforeAction = false,
    showToggleOption = false,
    toggleOptionText = '',
  } = props;

  let toggleSelected = true;

  const toggleValueChanged = (checked) => {
    toggleSelected = checked;
  };

  return (
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="confirmation-dialog">
          {showToggleOption && (
            <span>
              <p>
                {toggleOptionText}
              </p>
              <ToggleSwitch Name="deleteSurveys" onChange={toggleValueChanged} checked={true} />
            </span>
          )}
          <p>
            {`Are you sure you want to ${operation} ${entityName}?`}
          </p>
          <button onClick={onClose} type="button" className="reject">No</button>
          <button
            className="confirm"
            type="button"
            onClick={async () => {
              if (closeBeforeAction) {
                onClose();
                if (showToggleOption) {
                  await onDelete(toggleSelected);
                } else {
                  await onDelete();
                }
              } else {
                if (showToggleOption) {
                  await onDelete(toggleSelected);
                } else {
                  await onDelete();
                }
                onClose();
              }
            }}
          >
            Yes,
            {' '}
            {confirmationText}
            !
          </button>
        </div>
      ),
    }));
};
ConfirmDialog.propTypes = {
  entityName: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  closeBeforeAction: PropTypes.bool.isRequired,
  showToggleOption: PropTypes.bool.isRequired,
  toggleOptionText: PropTypes.string.isRequired,
};

export default ConfirmDialog;
