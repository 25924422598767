import toast from 'react-hot-toast';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const ApiRoot = `${process.env.REACT_APP_ENGAGEMENT_API_ROOT}/survey`;

axiosRetry(axios, {
  retries: 5,
  retryDelay: (retryCount) => retryCount * 3000,
});

export async function getAllSurveysAsync() {
  try {
    const result = await axios.get(ApiRoot);
    return result.data;
  } catch {
    toast.error('Error listing surveys please try again');
    return [];
  }
}

export async function getSurveyByIdAsync(id) {
  try {
    const result = await axios.get(`${ApiRoot}/${id}`);
    return result.data;
  } catch {
    toast.error('Error loading survey please try again');
    return {};
  }
}

export async function createSurveyAsync(survey) {
  let success = true;
  let isSurveyMissing = false;

  try {
    const result = await axios.post(ApiRoot, survey);
    if (result.status !== 201) success = false;
  } catch (exception) {
    if (exception.response.status === 409) {
      isSurveyMissing = true;
    }

    success = false;
  }

  if (success) toast.success('Survey created successfully');
  else toast.error('Error creating survey please try again');

  return { success, isSurveyMissing };
}

export async function updateSurveyAsync(id, survey) {
  let success = true;
  let isSurveyMissing = false;

  try {
    const result = await axios.put(`${ApiRoot}/${id}`, survey);
    if (result.status !== 200) success = false;
  } catch (exception) {
    if (exception.response.status === 409) {
      isSurveyMissing = true;
    }

    success = false;
  }

  if (success) toast.success('Survey updated successfully');
  else toast.error('Error updating survey please try again');

  return { success, isSurveyMissing };
}

export async function deleteSurveyAsync(id) {
  let success = true;
  let isConflicted = false;
  let conflicts = [];

  try {
    const result = await axios.delete(`${ApiRoot}/${id}`);
    if (result.status !== 204) success = false;
  } catch (exception) {
    if (exception.response.status === 409) {
      isConflicted = true;
      conflicts = exception.response.data;
    }

    success = false;
  }

  if (success) toast.success('Survey deleted successfully');
  else toast.error('Error deleting survey please try again');

  return { success, isConflicted, conflicts };
}

export async function refreshSurveyMetadata(id) {
  let success = true;

  try {
    const result = await axios.put(`${ApiRoot}/${id}/metadata`);
    if (result.status !== 200) success = false;
  } catch (exception) {
    success = false;
  }

  if (success) toast.success('Metadata refresh was requested successfully, this may take a few minutes to complete');
  else toast.error('Error refreshing metadata refresh please try again');
}
