import { React } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

const BounceModal = function (props) {
  const { engagement, show, handleClose } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Engagement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>Email Address:</b>
          <br />
          {engagement?.emailAddress}
        </p>
        <p>
          <b>Attempted to Send On:</b>
          <br />
          {moment(engagement?.sendSurveyOn).format('DD/MM/yyyy')}
        </p>
        <p>
          <b>Bounce Type:</b>
          <br />
          {
            engagement?.bounceType === 'hard-bounce'
              ? 'Hard bounce'
              : 'Soft bounce'
          }
        </p>
        <p>
          <b>Attendee:</b>
          <br />
          {engagement?.firstName}
          { ' ' }
          {engagement?.lastName}
        </p>
        <p>
          <b>Course Details:</b>
          <br />
          {engagement?.activityCode}
          { ' - '}
          {engagement?.instanceCode}
        </p>
        <p>
          <b>Course Start Date:</b>
          <br />
          {moment(engagement?.courseStartDate).format('DD/MM/yyyy')}
        </p>
        <p className="url-container">
          <b>Survey:</b>
          <br />
          {engagement?.surveyName}
          <br />
          <br />
          <a href={engagement?.surveyUri} title="Survey URL" target="_blank" rel="noreferrer">{engagement?.surveyUri}</a>
        </p>
      </Modal.Body>
    </Modal>
  );
};
BounceModal.defaultProps = {
  engagement: null,
};
BounceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  engagement: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailAddress: PropTypes.string,
    bounceType: PropTypes.string,
    activityCode: PropTypes.string,
    instanceCode: PropTypes.string,
    courseStartDate: PropTypes.string,
    surveyName: PropTypes.string,
    sendSurveyOn: PropTypes.string,
    surveyUri: PropTypes.string,
  }),
};

export default BounceModal;
